import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from 'react';
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/animate.css'
import './assets/css/slick.css';
import './assets/css/off-canvas.css';
import './assets/fonts/linea-fonts.css';
import './assets/fonts/flaticon.css';
import './assets/css/magnific-popup.css';
import './assets/css/rs-spacing.css';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './Firebase';
import { v4 as uuidv4 } from 'uuid';

import AdminRoutes from './Component/Admin/AdminRoutes';
import checkAuth from './Component/Authentication/Auth';
import Loding from './Loding';
import axios from 'axios';

import { Modal } from 'react-bootstrap';
const Header = lazy(() => import('./Component/Header'));
const Home = lazy(() => import('./Component/Home'));
const Footer = lazy(() => import('./Component/Footer'));
const FullStackfundation = lazy(() => import('./Component/Program/FullStackfundation'));
const BackendDevelopment = lazy(() => import('./Component/Program/BackendDevelopment'));
const FullStackDevelopment = lazy(() => import('./Component/Program/FullStackProgram'));
const FrontendDevelopment = lazy(() => import('./Component/Program/FrontendDevelopment'));
const UianduxDevelopment = lazy(() => import('./Component/Program/UiDevelopment'));
const DigitalMarketing = lazy(() => import('./Component/Program/DigitalMarketing'));
const BCAProgram = lazy(() => import('./Component/Program/BCAProgram'));
const MCAProgram = lazy(() => import('./Component/Program/MCAProgram'));
const Club = lazy(() => import('./Component/Club'));
const Blog = lazy(() => import('./Component/Blog'));
const Team = lazy(() => import('./Component/Team'));
const AboutUS = lazy(() => import('./Component/AboutUs'));
const Contact = lazy(() => import('./Component/Contact'));
const FAQ = lazy(() => import('./Component/FAQ'));
const PrivacyAndPolicy = lazy(() => import('./Component/Privacy/PrivacyAndPolicy'));
const TermsAndCondition = lazy(() => import('./Component/Privacy/TermsAndCondition'));
//blogs
const FullStackwithai = lazy(() => import('./Component/Blogs/FullStackwithai'));
const KeyCommunication = lazy(() => import('./Component/Blogs/KeyCommunication'));
const TopThreeSkill = lazy(() => import('./Component/Blogs/TopThreeSkill'));
const TechnicalSeo = lazy(() => import('./Component/Blogs/TechnicalSeo'));
const Digitalmarketingwithai = lazy(() => import('./Component/Blogs/DigitalMarketWithai'));
const LearnEarlyAndEarntEarly = lazy(() => import('./Component/Blogs/LearnEarly'));
const CareerAfter12th = lazy(() => import('./Component/Blogs/CareerAfter12th'));
const TheSeoOptimization = lazy(() => import('./Component/Blogs/TheSeoOptimization'));
const CareerOptions = lazy(() => import('./Component/Blogs/CareerOption'));
//Users
const Login = lazy(() => import('./Component/Users/Login'));
const StudentText = lazy(() => import('./Component/Users/StudentText'));
const VerifyCertificate = lazy(() => import('./Component/Users/VerifyCertificate'));
const AdminLogin = lazy(() => import('./Component/Admin/AdminLogin'));
const ThankYou = lazy(() => import('./Component/ThankYou/ThankYou'));
const FullStackFoundPdf = lazy(() => import('./Component/QRpdf/FullStack'));
const FullStackPdf = lazy(() => import('./Component/QRpdf/FullStckDeveloper'));
const FrontEndPdf = lazy(() => import('./Component/QRpdf/FrontEndPdf'));
const BackEndPdf = lazy(() => import('./Component/QRpdf/BackEndPdf'));
const DigitalMarketingPdf = lazy(() => import('./Component/QRpdf/DigitalMarketing'));
const BlogCheck = lazy(() => import('./Component/Blogs/BlogData/Blog'))
const SingleBlog = lazy(() => import('./Component/Blogs/SingleBlog'));
function App() {
  async function tokenSend(deviceId, token) {
    try {
      const config = {
        method: "post",
        url: "/user/token",
        baseURL: "https://www.skillyards.com/api",
        headers: { 'content-type': 'application/json' },
        data: {
          deviceId: deviceId, token: token
        }
      }
      await axios(config)
    } catch (error) {
      console.log(error);

    }
  }
  const askForNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        const token = await getToken(messaging, {
          vapidKey: 'BE56TAmfg3FlWY4tVoghzN6LHenOLsWhx7QMtsr7n-HKAv5-iB8A0VdgHWVAIsJPQ9-biMD9FTGTTztlysmpaTs',
          serviceWorkerRegistration: registration
        });

        let deviceId = localStorage.getItem('deviceId');

        if (!deviceId) {
          deviceId = uuidv4();
          localStorage.setItem('deviceId', deviceId);
        }


        if (token) {
          tokenSend(deviceId, token)
        }
        console.log('FCM token:', token);
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };
  const [notificationData, setNotificationData] = useState(null); // State for notification
  const [smShow, setSmShow] = useState(false);


  useEffect(() => {
    askForNotificationPermission();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received in foreground:', payload);

      // Extract notification data
      const { title, body, image } = payload.notification;
      const { url_ridirect } = payload.data
      // Set the notification data and show the modal
      setNotificationData({ title, body, image, url_ridirect });
      setSmShow(true);  // Show modal when message is received
    });

    return () => unsubscribe();
  }, []);



  return (
    <BrowserRouter>
      <Suspense fallback={<Loding />}>
        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              <div className="d-flex jusitify-content-center">
                <a href={notificationData?.url_ridirect} target='_blank' className='text-center'>
                  {notificationData?.image && <img src={notificationData?.image} alt='notification' width={"100%"} style={{ borderRadius: "10px" }} />}

                  <h3>{notificationData?.title}</h3>
                  <p>{notificationData?.body}</p>
                </a>
              </div>
            </Modal.Title>
          </Modal.Header>

        </Modal>
        <Routes>
          <Route path='/' element={<><Header /><Home /><Footer /></>} />
          {/* our Programs */}
          <Route path='/foundation-training-program-in-agra' element={<><Header /><FullStackfundation /><Footer /></>} />
          <Route path='/back-end-web-developer-training-in-agra' element={<><Header /><BackendDevelopment /><Footer /></>} />
          <Route path='/full-stack-web-development-training-in-agra' element={<><Header /><FullStackDevelopment /><Footer /></>} />
          <Route path='/front-end-developer-training-in-agra' element={<><Header /><FrontendDevelopment /><Footer /></>} />
          <Route path='/graphic-designing-training-program-in-agra' element={<><Header /><UianduxDevelopment /><Footer /></>} />
          <Route path='/digital-marketing-training-in-agra' element={<><Header /><DigitalMarketing /><Footer /></>} />
          <Route path='/bca-training-program-in-agra' element={<><Header /><BCAProgram /><Footer /></>} />
          <Route path='/mca-training-program-in-agra' element={<><Header /><MCAProgram /><Footer /></>} />

          <Route path='/Club' element={<><Header /><Club /><Footer /></>} />
          {/* <Route path='/blogs' element={<><Header /><Blog /><Footer /></>} /> */}
          <Route path='/teams' element={<><Header /><Team /><Footer /></>} />
          <Route path='/about' element={<><Header /><AboutUS /><Footer /></>} />
          <Route path='/contact' element={<><Header /><Contact /><Footer /></>} />

          <Route path='/FAQ' element={<><Header /><FAQ /><Footer /></>} />
          <Route path='/privacypolicy' element={<><Header /><PrivacyAndPolicy /><Footer /></>} />
          <Route path='/termsandcondition' element={<><Header /><TermsAndCondition /><Footer /></>} />
          <Route path='/blogs' element={<><Header /><BlogCheck /><Footer /></>} />
          <Route path='/blogs/:title' element={<><Header /><SingleBlog /><Footer /></>} />

          {/* Blog */}
          <Route path='/Full-Stack-with-Ai' element={<><Header /><FullStackwithai /><Footer /></>} />
          <Route path='/key-communication-skill' element={<><Header /><KeyCommunication /><Footer /></>} />
          <Route path='/top-three-skills' element={<><Header /><TopThreeSkill /><Footer /></>} />
          <Route path='/technical-seo' element={<><Header /><TechnicalSeo /><Footer /></>} />
          <Route path='/ai-in-digital-marketing' element={<><Header /><Digitalmarketingwithai /><Footer /></>} />
          <Route path='/learn-early-and-earn-early-after-12th' element={<><Header /><LearnEarlyAndEarntEarly /><Footer /></>} />
          <Route path='/career-after-12th' element={<><Header /><CareerAfter12th /><Footer /></>} />
          <Route path='/The-Role-of-On-Page-SEO' element={<><Header /><TheSeoOptimization /><Footer /></>} />
          <Route path='/career-option' element={<><Header /><CareerOptions /><Footer /></>} />

          <Route path='/login' element={<><Header /><Login /><Footer /></>} />
          <Route path='/student-test' element={<StudentText />} />
          <Route path='/verify' element={<><Header /><VerifyCertificate /><Footer /></>} />
          <Route path='/thank-you' element={<><Header /><ThankYou /><Footer /></>} />
          <Route path='/full-stack-found-pdf' element={<><Header /><FullStackFoundPdf /><Footer /></>} />
          <Route path='/full_stack_pdf' element={<><Header /><FullStackPdf /><Footer /></>} />
          <Route path='/front-end-pdf' element={<><Header /><FrontEndPdf /><Footer /></>} />
          <Route path='/back-end-pdf' element={<><Header /><BackEndPdf /><Footer /></>} />
          <Route path='/digital-marketing-pdf' element={<><Header /><DigitalMarketingPdf /><Footer /></>} />

          {/* Admin Path */}
          <Route path='/admin/login' element={<AdminLogin />} />

          <Route path="/app/*" element={<AdminRoutes />} />

          {/* <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/admin/login"} replace />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
