import React from 'react'
// import './Component/style/style.css'
function Loding() {
    
    return (
        <> 
            <div id="" class="loader">
                <div class="loader-icon">
                    <img src="../Web_logo/front_weblogo.jpeg" alt="loading"  width={"100px"} loading="lazy"/>
                </div>
                <div class="loader-container">
                </div>

            </div>
           
        </>
    )
}

export default Loding