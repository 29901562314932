// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBcKlTi1DT5JL4rNehbjkFl8kNAn6ofECQ",
    authDomain: "skillyards-160ec.firebaseapp.com",
    projectId: "skillyards-160ec",
    storageBucket: "skillyards-160ec.appspot.com",
    messagingSenderId: "325189687980",
    appId: "1:325189687980:web:2395a4c0fba28c4da09d09",
    measurementId: "G-QZJD16EBF4"

};

const app = initializeApp(firebaseConfig);
// Get a messaging instance
const messaging = getMessaging(app);

export { messaging };
